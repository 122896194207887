<template>
  <section>
    <router-link :to="{ name: 'add-vendor' }">
      <b-button icon-left="plus" class="add-btn" type="is-light" rounded>
      </b-button>
    </router-link>

    <b-table
      class="mt-4"
      :data="vendors"
      ref="table"
      paginated
      searchable
      per-page="10"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      pagination-rounded
    >
      <b-table-column
        field="name"
        label="Name"
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.displayName }}
      </b-table-column>

      <b-table-column
        field="created"
        label="Account Status"
        sortable
        v-slot="props"
      >
        <b-tag v-if="props.row.isAccountCreated" type="is-success is-light">
          Created
        </b-tag>

        <b-tag v-else type="is-danger is-light"> Pending </b-tag>
      </b-table-column>

      <b-table-column label="" v-slot="props">
        <div @click="onClick(props.row)">
          <b-icon pack="fas" icon="arrow-right" size="is-small" />
        </div>
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No vendors have been registered</div>
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  VendorsActions,
  VendorsMutations,
  VendorsStates,
} from "../store/Storetypes";
export default {
  computed: {
    ...mapState({
      vendors: (state) => state.vendors[VendorsStates.VENDORS],
    }),
    vendorsComputed() {
      return this.vendors.filter((vendor) => vendor.isAccountCreated);
    },
  },
  created() {
    this.getVendors();
  },
  methods: {
    ...mapActions({
      getVendors: VendorsActions.GET_VENDORS,
    }),
    ...mapMutations({
      setSelectedVendor: VendorsMutations.SET_SELECTED_VENDOR_DATA,
    }),
    onClick(vendorData) {
      this.setSelectedVendor(vendorData);
      this.$router.push({
        name: "vendor-details",
        params: { vendorId: vendorData.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.b-table
  .table-wrapper.has-mobile-cards
  tr:not(.detail):not(.is-empty):not(.table-footer)
  td:before {
  content: attr(data-label);
  color: #a29ebb;
}

::v-deep {
    .add-btn {
      background-color: rgba(235,251,244,.4509803922);
      color: #0e3d4d;
      width: 3.5em;
      height: 3.5em;
    }
  }
</style>
